import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { styled } from '@mui/material/styles';
import { isNumber, isArray, isUndefinedOrNull } from '@twipped/utils';

const VoxGridRoot = styled('div', {
  name: 'VoxGrid',
  slot: 'Root',
})`
  display: grid;
`;

export const GridCell = styled('div', {
  name: 'VoxGrid',
  slot: 'Cell',
  shouldForwardProp: (prop) => ![
    'col',
    'colSpan',
    'row',
    'rowSpan',
  ].includes(prop),
})(
  () => ({
    position: 'relative',
    display: 'flex',
    justifyContent: 'stretch',
    alignItems: 'stretch',
    '> *': {
      flexGrow: 1,
      flexShrink: 1,
      flexBasis: 'auto',
    },
  }),
  ({ col }) => ({
    gridColumnStart: col,
  }),
  ({ colSpan }) => ({
    gridColumnEnd: `span ${colSpan}`,
  }),
  ({ row }) => ({
    gridRowStart: row,
  }),
  ({ rowSpan }) => ({
    gridRowEnd: `span ${rowSpan}`,
  }),
);

const Grid = forwardRef(function Grid ({
  columns,
  rows,
  className,
  style,
  component,
  grow,
  shrink,
  basis,
  auto,
  fill,
  ...other
}, ref) {
  const Component = component || 'div';

  style = {
    ...style,
  };

  if (grow === true) grow = 1;
  if (grow === false) grow = 0;
  if (shrink === true) shrink = 1;
  if (shrink === false) shrink = 0;

  if (fill) {
    style.flex = `1 1 ${basis || 'auto'}`;
  } else {
    if (!isUndefinedOrNull(grow)) style.flexGrow = grow;
    if (!isUndefinedOrNull(shrink)) style.flexShrink = shrink;
    if (basis) style.flexBasis = basis;
    else if (auto) style.flexBasis = 'auto';
  }

  if (isNumber(columns)) columns = [ ...Array(columns) ].map(() => '1fr').join(' ');
  else if (isArray(columns)) columns = columns.map((v) => (isNumber(v) ? v + 'fr' : v)).join(' ');
  if (columns) style.gridTemplateColumns = columns;

  if (isNumber(rows)) rows = [ ...Array(rows) ].map(() => '1fr').join(' ');
  else if (isArray(rows)) rows = rows.map((v) => (isNumber(v) ? v + 'fr' : v)).join(' ');
  if (rows) style.gridTemplateRows = rows;

  return (
    <VoxGridRoot
      {...other}
      as={Component}
      ref={ref}
      className={className}
      style={style}
    />
  );
});
Grid.displayName = 'Grid';
Grid.propTypes = {
  component: PropTypes.elementType,

  columns: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ])),
  ]),

  rows: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ])),
  ]),

  colSpacing: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),

  rowSpacing: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),

  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),

  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),

  spaced: PropTypes.bool,

  grow: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
  ]),

  shrink: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
  ]),

  basis: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),

  auto: PropTypes.bool,

  fill: PropTypes.bool,
};
Grid.Cell = GridCell;

export default Grid;
