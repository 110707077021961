/* eslint-disable react/no-multi-comp */
import { cl as classNames } from 'common/utils';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import SafeAnchor from 'common/ui/SafeAnchor';

import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const PageItem = forwardRef(({
  active = false,
  inactive,
  disabled = false,
  className,
  style,
  activeLabel = '(current)',
  children,
  ...props
}, ref) => {
  const Component = active || disabled ? 'span' : SafeAnchor;
  return (
    <li
      ref={ref}
      style={style}
      className={classNames(
        className,
        'PaginationItem-root',
        active && 'PaginationItem-active',
        inactive && 'PaginationItem-inactive',
        disabled && 'PaginationItem-disabled',
      )}
    >
      <Component className={'PaginationLink-root'} disabled={disabled} {...props}>
        {children}
        {active && activeLabel && (<span className={'PaginationItem-label'}>{activeLabel}</span>)}
      </Component>
    </li>
  );
});
PageItem.propTypes = {
  /** Disables the PageItem */
  disabled: PropTypes.bool,
  /** Styles PageItem as active, and renders a `<span>` instead of an `<a>`. */
  active: PropTypes.bool,
  /** Styles PageItem as inactive, and renders a `<span>` instead of an `<a>`. */
  inactive: PropTypes.bool,
  /** An accessible label indicating the active state.. */
  activeLabel: PropTypes.string,
};
PageItem.displayName = 'PageItem';
export default PageItem;

function createButton (name, defaultValue, label = name) {
  function Button ({ children, ...props }) {
    return (<PageItem {...props}>
      {children || defaultValue}
      <span className={'PaginationItem-label'}>{label}</span>
    </PageItem>);
  }
  Button.displayName = name;
  return Button;
}

export const First = createButton('First', <KeyboardDoubleArrowLeftIcon />);
export const Prev = createButton('Prev', <KeyboardArrowLeftIcon />, 'Previous');
export const Ellipsis = createButton('Ellipsis', <MoreHorizIcon />, 'More');
export const Next = createButton('Next', <KeyboardArrowRightIcon />);
export const Last = createButton('Last', <KeyboardDoubleArrowRightIcon />);
