import { useCallback, useEffect, useState } from 'react';
import usePipeline from 'common/pipelines/usePipeline';
import Typography from '@mui/material/Typography';
import Row from 'common/ui/Row';
import { observer } from 'mobx-react-lite';
import { styled } from '@mui/material/styles';
import { css } from '@mui/system';
import Grid from 'common/ui/Grid';
import { cl, isNumber } from '@twipped/utils';
import Column from 'common/ui/Column';
import { useWindowWide } from "./UseWindowsSize"
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

import DescriptionIcon from '@mui/icons-material/Description';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import MailIcon from '@mui/icons-material/Mail';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import BalanceIcon from '@mui/icons-material/Balance';
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';

const PipelineListRowRoot = styled("div")(css`

  width: 100%;
  padding: 0.5em 12px;
  white-space:nowrap;
  .product-name {
    margin: 0px 0px;
    background: #bdcbd9;
    padding: 2px 8px;
    color: white;
    font-size: 8pt;
    font-weight: 600;
    width: -moz-fit-content;
    width: fit-content;
  }
  .iconwidth{
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 32px;
  }
  .datecolumn{
    display: flex;
    min-width: 114px;
  }
  .fulladdress{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    min-width: 184px;
  }
  .escrownum{
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 140px;
  }
  .clientname{
      display: flex;
    align-items: center;
    justify-content: center;
    min-width: 125px;
  }
  .orderadmin{
    display: flex;
    justify-content: center;
    min-width: 125px;
  }
    
    .inner {
      gap: 0 4px;
    }
  `);

const PipelineListCellRoot = styled("div")(css`
    &.PipelineListCell-visible {
      margin-right: 10px;
      @media screen and (max-width: 1439px) {
        display: none;
      }
    }
  `);

const PipelineHeaderCell = styled(Typography, {
  name: 'PipelineHeaderCell',
})(
  ({ theme }) => (css`
    cursor: pointer;
    font-size: 12px;
    display: flex;
    align-items: center;
    font-weight: bold;
    white-space:nowrap;
    margin:4px;
    
    svg {
      margin:0 auto;
    }
  `),
);


const PipelineHeader = observer(function PipelineHeader() {
  const {
    pipeline,
    setPagination,
    sortFilteredPipelines
  } = usePipeline();
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const columnStructure = ['min-content']

  pipeline.columns.map((c) => {
    if (!c.viewport || width > c.viewport) {
      columnStructure.push(isNumber(c.size) ? `${c.size}fr` : c.size)
    }
  })

  const onClickSortIcon = (id) => {
    if (pipeline.filteredPipelines) 
      sortFilteredPipelines(id)
    else
      pipeline.sort(id);
    setPagination({ pageStart: 0 });
  };

  const currentColumn = pipeline.sortBy && pipeline.getColumn(pipeline.sortBy);

  const sortIcon = (column_id) => {
    if (!currentColumn || currentColumn.id != column_id)
      return null;

    if (pipeline.sortDirection > 0)
      return <ArrowDownwardIcon sx={{ marginLeft: '3px', fontSize: '15px' }}/>
    else
      return <ArrowUpwardIcon sx={{ marginLeft: '3px', fontSize: '15px' }}/>
  }

  const tableText = column => {
    if (column.icon) {
      switch (column.icon) {
        case 'DescriptionIcon':
          return <DescriptionIcon />
        case 'PersonAddIcon':
          return <PersonAddIcon />
        case 'MailIcon':
          return <MailIcon />
        case 'AssignmentIndIcon':
          return <AssignmentIndIcon />
        case 'AssignmentTurnedInIcon':
          return <AssignmentTurnedInIcon />
        case 'ScaleIcon':
          return <BalanceIcon/>
        case 'SearchIcon':
          return <FindInPageOutlinedIcon/>
        case 'SimCardDownloadIcon':
          return <SimCardDownloadIcon sx={{transform: 'scaleX(-1)'}}/>
      }
    }
    else {
      return column.caption;
    }
  }

  console.log("HEADER ...", pipeline.columns, pipeline)

  return (
    <Row>
      <PipelineListRowRoot
        className={cl(
          'PipelineListRow',
        )}
      >
        <Grid columns={columnStructure} className="inner">
          <Column sx={{minWidth: '40px'}}/>
          {pipeline.columns.map((column, i) => (
            <PipelineListCellRoot
                className={cl(
                  'PipelineListCell',
                  column.className,
                )}
                style={column.style}
                key={i} 
              >
                <PipelineHeaderCell variant="button" color="primary" onClick={() => onClickSortIcon(column.id)}>
                  {tableText(column)}
                  {sortIcon(column.id)}
                </PipelineHeaderCell>
            </PipelineListCellRoot>
          ))}
        </Grid>
      </PipelineListRowRoot>
    </Row>
  );
});
PipelineHeader.displayName = 'PipelineHeader';

export default PipelineHeader;
