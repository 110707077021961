
import AbstractGC from './abstract';

export default class DashboardAnalytics extends AbstractGC {

  async admin () {
    return await this.get('/analytics/dashboard');
  }


}
