import { useRef, useCallback, Suspense, lazy, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { styled } from '@mui/material/styles';
import { useSearchParams } from 'react-router-dom';
import FirmDetailsModal from './FirmDetailsModal';
import Loading from 'pages/loading';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
// import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import { alpha, css } from "@mui/system";

const OrderDetailsSubPage = lazy(() => import('pages/admin/order/details'));

const PopinModal = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    maxWidth: '1400px',
  },
  '& .MuiDialogTitle-root': {
    margin: 0,
    padding: 0,
    paddingLeft: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const ModalHeader = styled('div')(({ theme }) => (css`
    background: ${theme.palette.primary.main};
    position: relative;
    color: #fff;
    padding: 40px 40px 30px 40px;
    .orderdetailsclose{
      position: absolute;
      top: 10px;
      right: 10px;
      color: #a3baea;
    }
    .orderdetailsheader{
      font-weight: 400;
      font-size: 30px
    }
    .orderdetailssubheader{
      color: #a3baea;
    }
  `), );

const ModalContent = styled('div')(({ theme }) => (css`
    padding: 0;
  `), );

const TRIGGER_PARAM = 'orderDetail';

const OrderDetailsModal = observer(function OrderDetailsModal(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [showFirmDetails, setShowFirmDetails] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState();
  const [partId, setPartId] = useState(0);
  const orderid = searchParams.get(TRIGGER_PARAM);
  const paramsRef = useRef();
  paramsRef.current = searchParams;

  const closeModal = useCallback(() => {
    const newParams = new URLSearchParams(paramsRef.current);
    newParams.delete(TRIGGER_PARAM);
    setSearchParams(newParams);
  });

  return (
    <PopinModal
      onClose={closeModal}
      aria-labelledby="dialog-title"
      scroll="paper"
      fullWidth
      maxWidth="lg"
      open={!!orderid}
    >
      <ModalHeader>
        <Typography variant="42" component="h2" className="orderdetailsheader">
          Order Details ({orderid})
        </Typography>
        <Typography variant="h6" component="h6" className="orderdetailssubheader">
          Take notes, manage vendors, and keep your accounting up to date.
        </Typography>
        <IconButton
          aria-label="close"
          onClick={closeModal}
          className="orderdetailsclose"
          sx={{ color: (theme) => theme.palette.grey[500] }}
        ><CloseIcon /></IconButton>
      </ModalHeader>
      <ModalContent>
        <Suspense fallback={<Loading />}><OrderDetailsSubPage setSelectedCompanyId={setSelectedCompanyId} selectedCompanyId={selectedCompanyId} setShowFirmDetails={setShowFirmDetails} {...props} orderid={orderid} setPartId={setPartId} /></Suspense>
      </ModalContent>
      { showFirmDetails && <FirmDetailsModal setSelectedCompanyId={setSelectedCompanyId} setShowFirmDetails={setShowFirmDetails} setPartid={setPartId} partid={partId} orderid={orderid} /> }
    </PopinModal>
  );
});

export default OrderDetailsModal;

export function useOrderDetailModal() {
  const [searchParams] = useSearchParams();

  const paramsRef = useRef();
  paramsRef.current = searchParams;

  return function makeUrl(orderid) {
    const newParams = new URLSearchParams(paramsRef.current);
    newParams.set(TRIGGER_PARAM, orderid);
    return '?' + newParams.toString();
  };
}
