
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import { cl } from '@twipped/utils';
import { desaturate } from 'polished';
import { alpha } from '@mui/system';

const VoxTag = styled(Chip, {
  name: 'VoxTag',
  slot: 'Root',
  shouldForwardProp: (prop) => prop !== 'color',
})(({ theme, color }) => ({
  borderRadius: 10,
  backgroundColor: `${color}`,
  color: 'white',
  fontWeight: 500,
  margin: '2px',

  '.MuiChip-icon': {
    color: 'inherit',
  },
  '.MuiChip-deleteIcon': {
    color: '#ffffff99'
  }
}));

export default function Tag ({ className, ...props }) {
  return <VoxTag className={cl(className, 'VoxTag-root')} {...props} />;
}
