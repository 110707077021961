
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { forwardRef, useCallback } from 'react';
import { usePipelines } from 'common/pipelines/usePipelineCollection';
import usePipeline from 'common/pipelines/usePipeline';
import usePipelineServer from 'common/pipelines/usePipelineServer';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Chip from '@mui/material/Chip';
import { Observer } from 'mobx-react-lite';
import Row from 'common/ui/Row';

const PipelineTabs = forwardRef(function PipelineTabs ({ onChange, ...props }, ref) {
  const pipelines = usePipelines();

  const { error } = usePipelineServer();

  const {
    setPagination,
  } = usePipeline();

  const handleChange = useCallback((ev, key) => {
    onChange && onChange(ev, key);
    if (ev.defaultPrevented) return;
    setPagination({ pageStart: 0 });
  });

  return (
    <Tabs onChange={handleChange} {...props} ref={ref}>
      {pipelines.map((item) => (
        <Tab
          key={item.id}
          label={
            <Observer>{() => (
              <Row align="center">
                <Box sx={{ mr: 1 }}>{item.name}</Box>
                {error ? (
                  <Chip size="small" label="" />
                ) : (
                  <Chip size="small" label={Number(item.filteredPipelines ? item.filteredPipelines.length : item.length)} />
                )}
              </Row>
            )}</Observer>
          }
          value={item.id}
        />
      ))}
    </Tabs>
  );
});
PipelineTabs.displayName = 'PipelineTabs';
PipelineTabs.propTypes = {
  routeParam: PropTypes.string,
};

export default PipelineTabs;
