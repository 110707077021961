
import PropTypes from 'prop-types';

export default function PipelineConfig () {
  return null;
}

export const propTypes = PipelineConfig.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  condition: PropTypes.object,
  link: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      source: PropTypes.string,
      caption: PropTypes.string,
      size: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
      format: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
      ]),
      className: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
      ]),
      sortValue: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
      ]),
    }),
  ])),
  sortBy: PropTypes.string,
};
