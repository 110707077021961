import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import * as COLUMNS from '../columns';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { css, alpha } from '@mui/system';
import { cl, isFunction } from '@twipped/utils';

import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';

function Href({ href, ...props }) {
  if (href) return <Link to={href} {...props} />;
  return <div {...props} />;
}
Href.propTypes = { href: PropTypes.string };

const PipelineListCellRoot = styled(Href, {
  name: 'PipelineListCell',
})(
  ({ theme }) => (css`
    white-space:nowrap;

  
    &.PipelineListCell {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      text-decoration: inherit;

      font-size: 12px;
      line-height:1.25em;
      color:#757575;
      font-weight: ${theme.typography.fontWeightRegular};
      margin-left:5px;
      cursor: pointer;
    }

    &.PipelineListCell-hovered {
      background-color: ${theme.palette.action.hover};
    }

    &.PipelineListCell-locked {
      background-color: ${theme.palette.warning.light};
    }

    &.PipelineListCell-hovered.PipelineListCell-locked {
      background-color:
        ${alpha( theme.palette.warning.light, theme.palette.action.hoverOpacity )};
    }
    &.PipelineListCell-visible {
      margin-right: 10px;
      @media screen and (max-width: 1439px) {
        display: none;
      }
    }
    &.iconwidth{
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 32px;
    }
    &.datecolumn{
      display: flex;
      min-width: 114px;
    }
    &.fulladdress{
      display: flex;
      align-items: flex-start;
      justify-content: center;
      white-space: normal;
      min-width: 184px;
    }
    &.escrownum{
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 140px;
    }
    &.clientname{
      display: flex;
      justify-content: center;
      min-width: 125px;
      white-space: normal;
    }
    &.orderadmin{
      display: flex;
      justify-content: center;
      min-width: 125px;
      white-space: normal;
    }
    span.subparttype {
      position:absolute;
      font-size:10px;
      padding-top:7px;
      font-weight:bold;
      color:#c4c4c6;
    }
  `),
);

const PipelineListCell = observer(({
  row,
  column,
  pipelineTab,
  ...rest
}) => {
  // console.log("column ...",column.format ,column)
  let format = column.format || 'Text';
  let Column;
  if (isFunction(format)) {
    Column = observer(format);
    format = 'Custom';
  }
  else {
    Column = observer(COLUMNS[format] || COLUMNS.Text);
  }

  let className = column.className;
  if (isFunction(className)) className = className({ row, column });

  const cellContent = column => {

    if (column.icon) {
      const icon_size = '20px';
      const circle_default = <CircleRoundedIcon sx={{ width: icon_size, height: icon_size, fill: '#c4c4c6' }} />;
      const circle_success = <CheckCircleRoundedIcon sx={{ width: icon_size, height: icon_size, fill: '#1de528' }} />;
      const circle_error = <ErrorRoundedIcon sx={{ width: icon_size, height: icon_size, fill: '#e32d16' }} />;
      let prop = row._properties;
      switch (column.icon) {
        case "DescriptionIcon":
          let partstatus;
          let part_prelim = prop.parts.find(el => { return el.type?.name?.includes('Preliminary Attorney') && el.status?.id !== 35 });
          part_prelim = part_prelim ? part_prelim : prop.parts.find(el => el.type?.name?.includes('Preliminary Attorney'));
          let part_final = prop.parts.find(el => { return el.type?.name?.includes('Final Attorney') && el.status?.id !== 35 });
          part_final = part_final ? part_final : prop.parts.find(el => el.type?.name?.includes('Final Attorney'));
          if (part_final && pipelineTab === "final") {
            prop.parts = [part_final];
          }
          else if (part_prelim && pipelineTab === "preliminary") {
            prop.parts = [part_prelim];
          }
          if (part_final) {
            partstatus = 'F';
          }
          else if (part_prelim) {
            partstatus = 'P';
          }
          else {
            return circle_default;
          }
          return <div className="relative"><InsertDriveFileOutlinedIcon sx={{ width: '30px', height: '30px', fill: '#c4c4c6' }} /> < span className = "subparttype" > { partstatus }
          </span> </div>
        case "PersonAddIcon":
          return (prop.parts.length > 0 && [1, 2].indexOf(prop.parts[0]?.status.id) === -1 && prop.parts[0]?.assigned != null) ? circle_success : circle_default;
        case "MailIcon":
          return prop.parts[0]?.outbound_emails_total > 0 ? circle_success : circle_default;
        case "AssignmentIndIcon":
          return (prop.parts.length > 0 && [3, 4, 5, 6, 7, 35, 13].indexOf(prop.parts[0]?.status.id) !== -1) ? circle_success : circle_default;
        case "AssignmentTurnedInIcon":
          return (prop.parts.length > 0 && [6, 7].indexOf(prop.parts[0]?.status.id) !== -1) ? circle_success : prop?.part_statusid === 35 ? circle_error : circle_default;
        case "SimCardDownloadIcon":
          return <SimCardDownloadIcon sx={{transform: 'scaleX(-1)'}} />
        default:
          return circle_default;
      }
    }
    else {
      return <Column {...rest} {...column.props} row={row} column={column} />
    }
  }

  return (
    <PipelineListCellRoot
      className={cl(
        'PipelineListCell',
        column.className,
      )}
      style={column.style}
    >
      {cellContent(column)}
    </PipelineListCellRoot>
  );
}, { displayName: 'PipelineListCell' });
PipelineListCell.displayName = 'PipelineListCell';
PipelineListCell.propTypes = {
  row: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
};

export default PipelineListCell;
