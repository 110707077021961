import { useCallback } from 'react';
import usePipeline from 'common/pipelines/usePipeline';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Row from 'common/ui/Row';
import SortIcon from '@mui/icons-material/Sort';
import IconButton from '@mui/material/IconButton';
import { observer } from 'mobx-react-lite';
import { styled } from '@mui/material/styles';
import { css } from '@mui/system';
import PipelineFilter from './PipelineFilter';

const SortSelect = styled(Select)(css`
  margin-left: 1ch;

  .MuiSelect-select {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    padding-top: 2px;
    padding-bottom: 2px;
  }
`);

const PipelineSort = observer(function PipelineSort () {
  const {
    pipeline,
    setPagination,
  } = usePipeline();

  const handleChange = useCallback((ev) => {
    pipeline.sort(ev.target.value);
    setPagination({ pageStart: 0 });
  }, [ pipeline ]);

  const handleFlip = useCallback(() => {
    pipeline.sort(pipeline.sortBy);
  });

  const currentColumn = pipeline.sortBy && pipeline.getColumn(pipeline.sortBy);

  return (
    <Row>
      <PipelineFilter/>
      <Typography variant="caption">SORT BY</Typography>
      <SortSelect
        labelId="pipeline-sort-label"
        id="pipeline-sort"
        value={currentColumn?.id || ''}
        onChange={handleChange}
        hiddenLabel
        variant="filled"
      >
        {pipeline.columns.map((column) => (
          <MenuItem key={column.id} value={column.id}>{column.sortCaption || column.caption}</MenuItem>
        ))}
      </SortSelect>

      <IconButton
        color="primary"
        aria-label={pipeline.sortDirection > 0 ? 'sort ascending' : 'sort descending'}
        onClick={handleFlip}
      >
        <SortIcon style={{ transform: pipeline.sortDirection > 0 ? 'scaleY(-1)' : null }} />
      </IconButton>
    </Row>
  );
});
PipelineSort.displayName = 'PipelineSort';

export default PipelineSort;
