
import { useLayoutEffect, useRef, useCallback, createContext, useContext } from 'react';
import useWillMount from '@twipped/hooks/useWillMount';
import useForceUpdate from '@twipped/hooks/useForceUpdate';
import useMemoObject from '@twipped/hooks/useMemoObject';
import { omit, isFunction } from '@twipped/utils';
import { forEachChild } from 'common/children';

import PropTypes from 'prop-types';
import Pipeline from './pipeline';
import PipelineConfig from './PipelineConfig';
import usePipelineServer from './usePipelineServer';
import useMap from '@twipped/hooks/useMap';

export const PipelineCollectionContext = createContext(null);

const propTypes = {
  initialConfigs: PropTypes.arrayOf(PropTypes.shape(PipelineConfig.propTypes)),
};

export function PipelineCollection ({ initialConfigs, children }) {

  const DataChannels = usePipelineServer();
  const pipelines = useMap();
  const orderRef = useRef([]);
  const forceUpdate = useForceUpdate();

  const add = useCallback((pipeline) => {
    if (!(pipeline instanceof Pipeline)) pipeline = new Pipeline(pipeline);
    pipeline.bind(DataChannels.subscribe(pipeline.source));

    pipelines.set(pipeline.id, pipeline);
    orderRef.current.push(pipeline.id);
  });

  const remove = useCallback((id) => {
    const pipeline = pipelines.get(id);
    if (!pipeline) return;

    pipeline.bind(null);
    DataChannels.unsubscribe(pipeline.source);

    orderRef.current = omit(orderRef.current, pipeline.id);
    forceUpdate();
  });

  const get = useCallback((id) => pipelines.get(id));

  const list = useCallback(function* () {
    for (const id of orderRef.current) {
      if (pipelines.has(id)) yield pipelines.get(id);
    }
  });

  useWillMount(() => {
    if (initialConfigs) {
      for (const config of initialConfigs) {
        add(config);
      }
    }

    forEachChild(children, (child) => {
      if (child.type !== PipelineConfig) return;
      add(child.props);
    });
  });

  useLayoutEffect(() => {
    for (const pipeline of pipelines.values()) {
      pipeline.bind(DataChannels.subscribe(pipeline.source));
    }

    return () => {
      for (const pipeline of pipelines.values()) {
        pipeline.unbind();
        DataChannels.unsubscribe(pipeline.source);
      }
    };
  });

  const context = useMemoObject({ add, remove, get, list });

  if (isFunction(children)) {
    children = children(context);
  }

  return (
    <PipelineCollectionContext.Provider value={context}>{children}</PipelineCollectionContext.Provider>
  );
}
PipelineCollection.propTypes = propTypes;
PipelineCollection.Config = PipelineConfig;

export {
  PipelineConfig,
};

export default function usePipelineCollection () {
  return useContext(PipelineCollectionContext) || {};
}

export function usePipelines () {
  const { list } = useContext(PipelineCollectionContext) || {};
  return Array.from(list());
}

export function usePipeline (id) {
  const { get } = useContext(PipelineCollectionContext) || {};
  return get(id);
}
