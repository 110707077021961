import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export default function SafeAnchor ({ href, ...props }) {
  if (href) return <Link to={href} {...props} />;
  return <div {...props} />;
}
SafeAnchor.propTypes = {
  href: PropTypes.string,
};
