import { cl } from 'common/utils';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { css } from '@mui/system';
import { styled } from '@mui/material/styles';


const PaginationRoot = styled('ul', {
  name: 'Pagination',
  shouldForwardProp: (prop) => prop !== 'color',
})(
  ({ theme, color = 'primary' }) => (css`
    &.Pagination-root {
      display: flex;
      padding-left: 0;
      list-style: none;
      margin: 0;
      border-radius: .25rem;
    }

    .PaginationLink-root {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 2.5rem;

      position: relative;
      padding: 0 .75rem;
      margin-left: -1px;
      line-height: 1.25;
      color: ${theme.palette[color].main};
      text-decoration: none;
      font-weight: ${theme.typography.fontWeightMedium};
      background-color: ${theme.palette.background.paper};
      border: 1px solid ${theme.palette.grey[300]};

      &:hover {
        z-index: 2;
        color: ${theme.palette[color].dark};
        text-decoration: none;
        background-color: ${theme.palette.action.hover};
        border-color: ${theme.palette.grey[300]};
      }

      &:focus {
        z-index: 3;
        outline: 0;
        box-shadow: 0 0 0 .2rem rgba(${theme.palette[color].main}, .25);
      }
    }

    .PaginationItem-root {
      display: flex;

      &:not(.PaginationItem-disabled) {
        cursor: pointer;
      }

      &:first-of-type {
        .PaginationLink-root {
          margin-left: 0;
          border-top-left-radius: 0.25rem;
          border-bottom-left-radius: 0.25rem;
        }
      }

      &:last-of-type {
        .PaginationLink-root {
          border-top-right-radius: 0.25rem;
          border-bottom-right-radius: 0.25rem;
        }
      }

      &.PaginationItem-active .PaginationLink-root {
        z-index: 3;
        color: ${theme.palette.getContrastText(theme.palette[color].main)};
        background-color: ${theme.palette[color].main};
        border-color: ${theme.palette[color].main};
      }

      &.PaginationItem-inactive {
        pointer-events: none;
        cursor: auto;
      }

      &.PaginationItem-disabled .PaginationLink-root {
        color: ${theme.palette.grey[600]};
        pointer-events: none;
        cursor: auto;
        background-color: ${theme.palette.background.paper};
        border-color: ${theme.palette.grey[300]};
      }
    }

    .PaginationItem-label {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px; // Fix for https://github.com/twbs/bootstrap/issues/25686
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border: 0;
    }
  `),
);


const Pagination = forwardRef(({
  className,
  color = 'primary',
  ...props
}, ref) => (
  <PaginationRoot
    ref={ref}
    {...props}
    color={color}
    className={cl(
      className,
      'Pagination-root',
    )}
  />
));
Pagination.propTypes = {
  color: PropTypes.oneOf([ 'primary', 'secondary', 'info', 'success', 'warning', 'error' ]),
};

export default Pagination;
