/* eslint react/prop-types: 0 */

import { useCallback } from 'react';
import { cl, map } from 'common/utils';
import { format as formatDate, isValid as dateIsValid } from 'date-fns';
import { humanTime, relativeTime, hoursDifference } from 'common/time';
import businesshours from 'common/businesshours';
import usePipeline from '../usePipeline';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import PipelineStatusCell from './PipelineStatusCell';
import Tag from 'common/ui/Tag';
import Column from 'common/ui/Column';
import Row from 'common/ui/Row';
import moment from "moment";
import Svg from 'common/ui/Svg';
import Lock from 'common/svgs/lock.svg';
import TagSolid from 'common/svgs/tag-solid.svg';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

export function Disclose({ expanded, toggleExpanded }) {
  if (expanded) {
    return <RemoveCircleOutlineIcon onClick={toggleExpanded} />;
  }

  return <AddCircleOutlineIcon onClick={toggleExpanded} />;
}

export function Check({ row }) {
  const { selected, onRowSelect } = usePipeline();

  return (
    <Checkbox
      onChange={useCallback(() => { onRowSelect(row && row.id); })}
      checked={selected.has(row.id)}
      sx={{ padding: '1px' }}
    />
  );
}

export function Text({ column, row }) {
  console.log("Column.target ...", column.target, " ... Value ... ", row.get(column.target), " ... Row ...", row)
  const value = row.get(column.target);
  if (!value) return null;
  return (<span title={value}>{String(value)}</span>);
}

export function Counter({ column, row }) {
  const value = row.get(column.target);
  if (!value) return null;
  return (<span className="badge" title={`${value} Unread Emails`}>{value}</span>);
}

export function AddressFull({ row }) {
  const property = row.get('property');
  if (!property) return null;

  const line1 = [property.address, property.address2].map((s) => String(s).trim()).filter(Boolean).join(', ');
  const line2 = [
    property.city,
    [
      property.state,
      // canadian zipcodes insert a space
      property.zipcode.length === 6 ? property.zipcode.slice(0, 3) + ' ' + property.zipcode.slice(-3) : property.zipcode,
    ].map((s) => String(s).trim()).filter(Boolean).join(' '),
  ].map((s) => String(s).trim()).filter(Boolean).join(', ');

  return (
    <Row wrap title={`${line1}, ${line2}`} sx={{display: 'grid'}}>
      {line1 && <span>{line1}</span>}
      {line2 && <span>{line2}</span>}
    </Row>
  );
}

export function AddressSimple({ row }) {
  const property = row.$.property;
  if (!property) return null;
  const result = [
    property.address,
    property.address2 ? ', ' + property.address2 : '',
  ].join('');
  return (
    <span title={result}>{result}</span>
  );
}

export function PostalCode({ row }) {
  const property = row.$.property;
  if (!property) return null;
  const result = [
    // canadian zipcodes insert a space
    property.zipcode.length === 6 ? property.zipcode.slice(0, 3) + ' ' + property.zipcode.slice(-3) : property.zipcode,
    property.zipcode_plus4 ? '-' + property.zipcode_plus4 : '',
  ].join('');
  return (
    <span title={result}>{result}</span>
  );
}

export function DateTime({ column, row }) {
  const d = row.get(column.target);
  if (!dateIsValid(d)) return null;
  return (<><span>{formatDate(d, 'MM/dd/yyyy')}</span><span>{formatDate(d, 'HH:mm aa')}</span></>);
}

export function Date({ column, row }) {
  const d = row.get(column.target);
  if (!dateIsValid(d)) return null;
  return (<span>{formatDate(d, 'MM/dd/yyyy')}</span>);
}

export function Currency({ column, row }) {
  let value = row.get(column.target);
  if (value === undefined) return null;
  value = Number(value).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'symbol',
    minimumFractionDigits: 2,
    maximumSignificantDigits: 2,
    useGrouping: true,
  });
  return (<span>{value}</span>);
}

export function CurrencyLong({ column, row }) {
  let value = row.get(column.target);
  if (value === undefined) return null;
  value = Number(value).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'symbol',
    minimumFractionDigits: 0,
    maximumSignificantDigits: 0,
    useGrouping: true,
  });
  return (<span>{value}</span>);
}

export function TagsIcon({ row }) {
  if (!row.$.tags || !row.$.tags.length) return null;
  const tags = map(row.$.tags, 'label');
  return (<span className="fill" title={tags.join(', ')}>{tags && <Svg><TagSolid /></Svg>}</span>);
}

export function TagsList({ row }) {
  if (!row.$.tags || !row.$.tags.length) return null;
  const tags = map(row.$.tags, (t) => (
    <Tag
      key={t.id}
      icon={<Svg><TagSolid /></Svg>}
      size="small"
      color={t.color}
      label={t.label}
    />
  ));
  return <Row wrap>{tags}</Row>;
}

export function List({ column, row }) {
  const list = row.get(column.target) || [];
  return list.map((l, i) => <span key={i}>{l}</span>);
}

export function TimeLastTouched({ row }) {
  let lt = row.get('date.last_touched');
  if (!dateIsValid(lt)) lt = row.get('date.ordered');
  const duration = Math.max(0, businesshours(lt));
  const result = humanTime(duration);
  return (
    <span className={cl( duration > 14400 && 'l-error' )} title={result}>{result}</span>
  );
}

export function TimeStartQC({ row }) {
  const duration = Math.max(0, dateIsValid(row.get('date.effective')) ? businesshours(row.get('date.effective')) : 0);
  const result = humanTime(duration);
  return (
    <span className={cl( duration > 14400 && 'l-error' )} title={result}>{result}</span>
  );
}

export function PipelineStatus({ column, row }) {
  const ps = row.get(column.target);
  if (!ps) return null;
  return (<PipelineStatusCell className={ps.class} title={ps.title} />);
}

export function Locked({ row }) {
  return (
    <span>{row.$.locked ? <Svg><Lock /></Svg> : null}</span>
  );
}

export function RelativeTime({ column, row }) {
  try {
    let d = row.get('date.rejected') ? row.get('date.rejected') : '';
    if (!dateIsValid(d)) return null;
    return (
      <Box sx={{color:hoursDifference(row.get('date.rejected'))>24 ? 'red':''}}>
        <span>{relativeTime( row.get('date.rejected'))}</span>
      </Box>
    );
  }
  catch (err) {
    console.log("err-->", err);
  }
}

export function GetFirm({ column, row }) {
  let parts = row.get('parts');
  return (
    <span>{parts.find(el => el.agent !== null)?.agent?.compname}</span>
  )
}

export function GetVendorName({ column, row }) {
  let parts = row.get('parts');
  let vendorName = parts.find(el => { return el.agent !== null && el.status.id === 35 })?.agent?.name;
  return (
    <span>{vendorName?vendorName:''}</span>
  )
}

export function SetLongText({ column, row }) {
  let rejection_reason = row.get('rejection_reason');
  return rejection_reason ?
    rejection_reason.length > 25 ? `${rejection_reason.slice(0, 20)}...` : rejection_reason :
    ''
}

export function orderAnalyst({ column, row }) {
  let parts = row.get('parts');
  let orderPart = parts.filter(p => { p.type == 19 && ![7, 10, 35].includes(p.status) })
  return (
    <span>{orderPart ?orderPart.agent : null}</span>
  );
}
