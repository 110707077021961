import { useRef, useCallback, Suspense, lazy } from 'react';
import { observer } from 'mobx-react-lite';
import { styled } from '@mui/material/styles';
import { useSearchParams } from 'react-router-dom';

import Loading from 'pages/loading';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import {alpha, css} from "@mui/system";

const FirmDetailsSubPage   = lazy(() => import('pages/admin/order/firm'));

const PopinModal = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogTitle-root': {
    margin: 0,
    padding: 0,
    paddingLeft: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const ModalHeader = styled('div')(({ theme }) => (css`
    background: ${theme.palette.primary.main};
    position: relative;
    color: #fff;
    padding: 40px 40px 30px 40px;
    button {
      position: absolute;
      top: 10px;
      right: 10px;
      color: #a3baea;
    }
    .header-title {
      font-weight: 400;
      font-size: 30px
    }
    .header-description {
      color: #a3baea;
    }
  `),
);

const ModalContent = styled('div')(({ theme }) => (css`
    
  `),
);

const TRIGGER_PARAM = 'orderDetail';

const FirmDetailsModal = observer(function FirmDetailsModal ({setSelectedCompanyId,setPartid, setShowFirmDetails, partid, orderid}) {
  const [ searchParams, setSearchParams ] = useSearchParams();
  orderid = searchParams.get(TRIGGER_PARAM) ?? orderid;

  const paramsRef = useRef();
  paramsRef.current = searchParams;

  const closeModal = () => {
    setShowFirmDetails(false);
  };

  return (
    <PopinModal
      onClose={closeModal}
      aria-labelledby="dialog-title"
      scroll="paper"
      fullWidth
      maxWidth="lg"
      open={!!orderid}
    >
      <ModalHeader>
        <Typography variant="42" component="h2" className='header-title'>
          Assign Law Firm
        </Typography>
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{ color: (theme) => theme.palette.grey[500] }}
        ><CloseIcon /></IconButton>
      </ModalHeader>
      <ModalContent>
        <Suspense fallback={<Loading />}>
        <FirmDetailsSubPage setSelectedCompanyId={setSelectedCompanyId} orderid={orderid} setPartid={setPartid} partid={partid} closeModal={closeModal}/>
        </Suspense>
      </ModalContent>
    </PopinModal>
  );
});

export default FirmDetailsModal;