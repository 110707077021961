import PropTypes from 'prop-types';
import { useCallback, useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom'
import { styled } from '@mui/material/styles';
import { css, alpha } from '@mui/system';
import Paper from '@mui/material/Paper';
import _ from 'lodash';
import Box from '@mui/material/Box';
import { observer } from 'mobx-react-lite';
import { cl, isNumber, map } from '@twipped/utils';
import { Link, useLinkClickHandler } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

import Collapse from '@mui/material/Collapse';
import PipelineListCell from './Cell';
import Grid from 'common/ui/Grid';
import useToggledState from '@twipped/hooks/useToggledState';
import Tag from 'common/ui/Tag';
import Row from 'common/ui/Row';
import Column from 'common/ui/Column';
import IconButton from '@mui/material/IconButton';

import Svg from 'common/ui/Svg';
import TagSolid from 'common/svgs/tag-solid.svg';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useWindowWide } from "../UseWindowsSize"
import { Api } from "core/api/base";
import Order from "common/models/order";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function Hrefable({ href, ...props }) {
  if (href) return <Paper component={Link} to={href} {...props} />;
  return <Paper {...props} />;
}
Hrefable.propTypes = {
  href: PropTypes.string,
  state: PropTypes.object,
  modal: PropTypes.bool,
};


const PipelineListRowRoot = styled(Hrefable, {
  name: 'PipelineListRow',
})(
  ({ theme }) => (css`
  
    border-radius:0;
    box-shadow:none !important;
  
    &.PipelineListRow {
      display: flex;
      flex-direction: column;
      text-decoration: inherit;

      margin: 0.5em 0;
    }

    .inner {
      padding: 0.5em;
      gap: 0 4px;
    }

    &:hover {
      background-color: ${theme.palette.action.hover};
    }

    &.PipelineListRow-locked {
      background-color: ${theme.palette.warning.light};
    }

    &:hover.PipelineListRow-locked {
      background-color:
        ${alpha(theme.palette.warning.light, theme.palette.action.hoverOpacity)};
    }

    .PipelineListRow-tags {
      border-top: 1px solid ${theme.palette.divider};
      padding: 0.5em;
    }

    .PipelineListRow-constrain-tags {
      max-height: 1.7em;
      overflow: hidden;
    }

    .PipelineListRow-expanded {
      border-top: 1px solid ${theme.palette.divider};
      padding: 0.5em;
    }
    
    .PipelineListRow-expanded th {
      min-height:65px;
    }
    
    .product-name {
      margin: 0px 0px;
      background: #bdcbd9;
      padding: 2px 8px;
      color: white;
      font-size: 8pt;
      font-weight: 600;
      width: -moz-fit-content;
      width: fit-content;
    }
  `),
);

const PipelineListRow = observer(function PipelineListRow({
  row,
  columns,
  href,
  refresh,
  children,
  orderTagOptions,
  openRow,
  onRowClick: parentOnRowClick,
  onRowToggle: parentOnRowToggle
}) {
  const { state: open, toggle: toggleOpen } = useToggledState();
  const handleLinkClick = useLinkClickHandler(href || '');
  const theme = useTheme();
  const [addNewTag, setAddNewTag] = useState(false);
  const [newTag, setNewTag] = useState('');
  const [orderTags, setOrderTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState();
  const [toastState, setToastState] = useState({ open: false, message: "", severity: 'success' });
  const api = (new Order);

  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleClickOpen = () => {
    setConfirmOpen(true);
  };

  const handleClose = () => {
    setConfirmOpen(false);
  };

  useEffect(() => {
    if (toastState.open) {
      setTimeout(() => {
        toastMessage()
      }, 3000)
    }
  }, [toastState.open])

  useEffect(() => {
    setOrderTags(row.$.tags)
  }, [row])


  const onRowClick = useCallback((ev) => {
    parentOnRowClick && parentOnRowClick(ev, { row, columns });
    if (ev.defaultPrevented) return;
    handleLinkClick(ev);
  });

  const toggleCollapse = useCallback((ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    if (openRow == row.id || (!open && openRow != row.id))
      toggleOpen();
    parentOnRowToggle && parentOnRowToggle(ev, { row, columns });

  });

  const columnStructure = [];
  if (children) columnStructure.unshift('min-content');

  columns.map((c) => {
    if (!c.viewport || useWindowWide(c.viewport))
      columnStructure.push(isNumber(c.size) ? `${c.size}fr` : c.size)
  })

  if (href || parentOnRowClick) columnStructure.push('min-content');

  const toastMessage = (open = false, message, severity = "success") => {
    setToastState({
      open,
      message,
      severity
    })
  }

  const handleDeleteTag = (ev, t, orderId) => {
    setSelectedTag({ orderid: orderId, tagid: t.id });
    handleClickOpen();
    ev.preventDefault();
  }

  const onDeleteTag = async () => {
    try {
      const result = await api.removeOrderTags(selectedTag);
      if (result.status === "success") {
        const newTags = orderTags.filter((tag) => tag.id !== selectedTag.tagid)

        setOrderTags(newTags)

        toastMessage(true, "Order tag removed successfully, It will take some time to update in pipeline", "success")
      }
      else {
        toastMessage(true, "Please try again to remove tag", "error")
      }
    }
    catch (err) {
      toastMessage(true, err.message || "Error while load data", "error")
    }
    finally {
      refresh();
      handleClose();
      setSelectedTag({});
    }
  }

  const onAddTag = async (tagId, orderId) => {
    try {
      const result = await api.addOrderTags({ orderid: orderId, tagid: tagId });
      if (result.status === "success") {

        const match = _.find(orderTagOptions, { id: tagId });
        console.log({ orderTagOptions })
        setOrderTags([
          ...orderTags,
          match
        ])
        toastMessage(true, "Order tag Added successfully, It will take some time to update in pipeline", "success")
      }
      else {
        toastMessage(true, "Please try again to add tag", "error")
      }
    }
    catch (err) {
      toastMessage(true, err.message || "Error while load data", "error")
    }
    finally {
      refresh();
      setAddNewTag(false);
    }
  }

  const onChangeSelectBox = (e) => {
    const { value } = e.target;
    setNewTag(value);
  }

  const tags = map(orderTags, (t) => (
    <Tag
      key={t.id}
      size="small"
      color={t.color}
      label={t.label}
      onDelete={(e) => handleDeleteTag(e, t,row.id)}
    />
  ));

  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <PipelineListRowRoot
      href={href}
      className={cl(
        'PipelineListRow',
        row.locked && 'PipelineListCell-locked',
      )}
      data-rowid={row.id}
    >
      <Snackbar
        open={toastState.open}
        autoHideDuration={3000}
      >
        <Alert variant="filled" severity={toastState.severity}>
          {toastState.message}
        </Alert>
      </Snackbar>
      <Grid columns={columnStructure} className="inner" onClick={onRowClick}>
        {!!children && (
          <Column align="center" justify="center">
            <IconButton onClick={toggleCollapse}>
              {open && row.id == openRow ? <RemoveCircleOutlineIcon color="primary" /> : <AddCircleOutlineIcon color="primary" />}
            </IconButton>
          </Column>
        )}
        {columns.map((column, i) => (
          <PipelineListCell
            key={`${row.id}-c${i}`}
            row={row}
            column={column}
            pipelineTab={searchParams.get('pipeline')}
          />
        ))}
      </Grid>
      {!!children && (
        <Collapse in={open && row.id == openRow}>
          <Column className="PipelineListRow-expanded">{children}</Column>
        </Collapse>
      )}
      
      <Row align="top" className="PipelineListRow-tags">
        <Svg size="medium" sx={{ pt: '2px', color: theme.palette.grey[600] }}><TagSolid /></Svg>
        {!!tags.length && ( <Row wrap className={!open && 'PipelineListRow-constrain-tags'}>{tags}</Row>)}
        {!addNewTag ?
          <Svg size="small" sx={{ pt: '2px', color: theme.palette.grey[400] }} onClick={(e) => { setAddNewTag(true); e.stopPropagation() }}><AddCircleOutlineIcon /></Svg>
          : <>
            <FormControl sx={{ width: '200px',ml:1 }} variant="standard" className="orderTagWrapper">
              <Select
                id="demo-simple-select"
                value={newTag?.toString() || []}
                label="Order Tags"
              onChange={onChangeSelectBox}
              >
                {orderTagOptions.map((x, i) => 
                  ( <MenuItem
                    disabled={tags.map(t=>t.key).includes(`${x.id}`)}
                    key={i}
                    value={x.id}>
                    {x.label}
                  </MenuItem>)
                )}
              </Select>
            </FormControl>
            <Button sx={{mx:1}} variant="contained" size="small" disabled={!newTag} onClick={()=>onAddTag(newTag,row.id)}>
              Add
            </Button>
            <Button variant="outline" size="small" onClick={() => { setAddNewTag(false) }}>
              Cancel
            </Button>
          </>
        }
      </Row>
      <Dialog
        open={confirmOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Do you want to remove this tag?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={onDeleteTag} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </PipelineListRowRoot>
  );
});
PipelineListRow.displayName = 'PipelineListRow';
PipelineListRow.propTypes = {
  row: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  href: PropTypes.string,
};

export default PipelineListRow;
