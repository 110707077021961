
import { styled } from '@mui/material/styles';

const SvgIcon = styled('span', {
  name: 'VoxSvg',
  slot: 'Root',
  shouldForwardProp: (prop) => ![
    'size',
    'color',
  ].includes(prop),
})(({ theme, size, color }) => ({

  display: 'inline-flex',
  flexShrink: 0,
  fontSize: {
    inherit: 'inherit',
    small: theme.typography?.pxToRem?.(20) || '1.25rem',
    medium: theme.typography?.pxToRem?.(24) || '1.5rem',
    large: theme.typography?.pxToRem?.(35) || '2.1875',
  }[size],
  color:
    theme.palette?.[color]?.main ??
    {
      action: theme.palette?.action?.active,
      disabled: theme.palette?.action?.disabled,
      inherit: undefined,
    }[color],

  '> svg': {
    userSelect: 'none',
    width: '1em',
    height: '1em',
    display: 'inline-block',
    fill: 'currentColor',
    transition: theme.transitions?.create?.('fill', {
      duration: theme.transitions?.duration?.shorter,
    }),
  },
}));

export default SvgIcon;
