
import PropTypes from 'prop-types';
import { noop, clamp } from 'common/utils';
import { forwardRef, useRef, useCallback } from 'react';
import Pagination from 'common/ui/BSPagination';
import useToggledState from '@twipped/hooks/useToggledState';
import useDerivedState from '@twipped/hooks/useDerivedState';
import useGettableState from '@twipped/hooks/useGettableState';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Popover from '@mui/material/Popover';
import Paper from '@mui/material/Paper';

const Paginator = forwardRef(({
  onChange = noop,
  pageStart: oStart,
  pageLength: oLength,
  totalItems: count,
  sx,
  elevation,
  ...props
}, ref) => {

  const [ state, setState, getState ] = useDerivedState({ start: oLength ? oStart : 0, length: oLength }, [ oStart, oLength ]);
  const {
    state: menuVisible,
    toggle: toggleMenu,
    off: hideMenu,
  } = useToggledState();
  const [ inputValue, setInputValue, getInputValue ] = useGettableState(state.start + 1);
  const maxStart = Math.max(0, count - state.length);

  const onLeft = useCallback(() => {
    const { start, length } = getState();
    const newStart = Math.max(0, start - length);
    setState({ start: newStart, length });
    onChange(newStart, length);
  }, [ setState, getState, onChange ]);

  const onFullLeft = useCallback(() => {
    const { length } = getState();
    setState({ start: 0, length });
    onChange(0, length);
  }, [ setState, getState, onChange ]);

  const onFullRight = useCallback(() => {
    const { length } = getState();
    const newStart = Math.max(0, count - length);
    setState({ start: newStart, length });
    onChange(newStart, length);
  }, [ setState, getState, onChange ]);

  const onRight = useCallback(() => {
    const { start, length } = getState();
    const newStart = Math.min(start + length, count - length);
    setState({ start: newStart, length });
    onChange(newStart, length);
  }, [ setState, getState, onChange ]);

  const onLengthChange = useCallback((length) => {
    hideMenu();
    const { start } = getState();
    setState({ start: length ? start : 0, length }); // if length is 0, reset start to 0
    onChange(start, length);
  }, [ setState, getState, onChange ]);

  const onJumpKey = useCallback((ev) => {
    if (ev.key !== "Enter") return;
    const { length } = getState();
    const start = clamp(getInputValue() - 1, 0, maxStart);
    setState({ start, length });
    onChange(start, length);
    setInputValue(null);
    hideMenu();
  }, [ setState, getState, onChange ]);



  const captionEnd = state.length === 0 ? count : Math.min(count, clamp(state.start + state.length, state.length, count));
  const caption = count
    ? `${state.start + 1} - ${captionEnd} of ${count}`
    : 'No Results'
  ;
  const menuRef = useRef(null);

  return (
    <Paper sx={sx} elevation={elevation}>
      <Pagination {...props} ref={ref}>
        <Pagination.First disabled={state.start === 0} onClick={onFullLeft} />
        <Pagination.Prev disabled={state.start === 0} onClick={onLeft} />
        <Pagination.Item disabled={!count} ref={menuRef} onClick={toggleMenu}>&nbsp;{caption}&nbsp;</Pagination.Item>
        <Pagination.Next disabled={!count || state.length === 0 || state.start >= maxStart} onClick={onRight} />
        <Pagination.Last disabled={!count || state.length === 0 || state.start >= maxStart} onClick={onFullRight} />
      </Pagination>
      <Popover
        open={menuVisible}
        anchorEl={menuRef.current}
        onClose={hideMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <MenuList>
          <PaginatorMenuItem value={10}  disabled={state.length === 10}  onChange={onLengthChange} />
          <PaginatorMenuItem value={25}  disabled={state.length === 25}  onChange={onLengthChange} />
          <PaginatorMenuItem value={50}  disabled={state.length === 50}  onChange={onLengthChange} />
          <PaginatorMenuItem value={100} disabled={state.length === 100} onChange={onLengthChange} />
          <PaginatorMenuItem value={200} disabled={state.length === 200} onChange={onLengthChange} />
          <PaginatorMenuItem value={0}   disabled={state.length === 0}   onChange={onLengthChange} label="All" />
          <Divider />
          <MenuItem>
            <InputBase
              type="number"
              placeholder="Go To..."
              step={state.length}
              min={1}
              max={maxStart}
              onKeyUp={onJumpKey}
              value={inputValue}
              onChange={setInputValue}
            />
          </MenuItem>
        </MenuList>
      </Popover>
    </Paper>
  );
});
Paginator.propTypes = {
  /**
   * Function to invoke when pagination changes
   * @type {[type]}
   */
  onChange: PropTypes.func,
  pageStart: PropTypes.number.isRequired,
  pageLength: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  sx: PropTypes.object,
  elevation: PropTypes.number,
};
export default Paginator;

function PaginatorMenuItem ({ value, label, onChange, ...props }) {
  return <MenuItem onClick={useCallback(() => onChange(value), [ value, onChange ])} {...props}><ListItemText>Show {value || label} Results</ListItemText></MenuItem>;
}
PaginatorMenuItem.propTypes = {
  value: PropTypes.number,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};
