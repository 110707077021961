import { cl } from 'common/utils';
import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { keyframes, css } from '@mui/system';
import { styled } from '@mui/material/styles';

const ROUND_PRECISION = 1000;
const progressBarStripes = keyframes`
  from { background-position: 2em 0; }
  to { background-position: 0 0; }
`;

const ProgressRoot = styled('div', {
  name: 'ProgressRoot',
  shouldForwardProp: (prop) => prop !== 'color',
})(
  ({ theme, color }) => (css`
    &.Progress-root {
      position: relative;
      display: flex;
      height: 1em;
      width: 100%;
      overflow: hidden;
      line-height: 0;
      background-color: ${theme.palette.grey[200]};
      border-radius: 0.25rem;
      box-shadow: inset 0 .1rem .1rem rgba(0,0,0, 0.1);
    }

    .ProgressBar-root {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      overflow: hidden;
      color: ${theme.palette[color]?.main};
      text-align: center;
      white-space: nowrap;
      background-color: ${theme.palette[color]?.main};
      transition: width .6s ease;
      z-index: 1;
    }

    .ProgressBar-striped {
      background-image: linear-gradient(45deg, currentColor 25%, transparent 25%, transparent 50%, currentColor 50%, currentColor 75%, transparent 75%, transparent);
    }

    .ProgressBar-animated {
      animation: ${progressBarStripes} 1s linear infinite;
    }

    .ProgressBarLabel {
      position: absolute;
      font-size: 0.75em;
      color: ${theme.palette[color]?.main};
      font-weight: ${theme.typography.fontWeightMedium};
      padding-left: 1ch;
      display: flex;
      align-items: center;
    }

    > .ProgressBarLabel {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      color: ${theme.palette[color]?.main};
      z-index: 0;
    }

    .ProgressBar-root > .ProgressBarLabel {
      color: ${theme.palette.getContrastText(theme.palette[color]?.main)};
    }
  `),
);


const propTypes = {
  /**
   * Minimum value progress can begin from
   */
  min: PropTypes.number,

  /**
   * Current value of progress
   */
  now: PropTypes.number,

  /**
   * Maximum value progress can reach
   */
  max: PropTypes.number,

  /**
   * Show label that represents visual percentage.
   * EG. 60%
   */
  label: PropTypes.node,

  /**
   * Uses a gradient to create a striped effect.
   */
  striped: PropTypes.bool,

  /**
   * Animate's the stripes from right to left
   */
  animated: PropTypes.bool,

  /**
   * Sets the background color of the progress bar.
   *
   * @type ('success'|'danger'|'warning'|'info')
   */
  color: PropTypes.oneOf([ 'primary', 'secondary', 'info', 'success', 'warning', 'error' ]),
};

function getPercentage (value, min, max) {
  const percentage = ((value - min) / (max - min)) * 100;
  return Math.round(percentage * ROUND_PRECISION) / ROUND_PRECISION;
}

const ProgressBar = forwardRef(({
  min,
  now = 0,
  max,
  label,
  striped,
  animated,
  color = 'primary',
  className,
  ...wrapperProps
}, ref) => (
  <ProgressRoot
    ref={ref}
    {...wrapperProps}
    className={cl(
      className,
      'Progress-root',
    )}
    color={color}
  >
    <div
      ref={ref}
      role="progressbar"
      className={cl(
        className,
        'ProgressBar-root',
        animated && 'ProgressBar-animated',
        (animated || striped) && 'ProgressBar-striped',
      )}
      style={{ width: `${getPercentage(now, min, max)}%` }}
      aria-valuenow={now}
      aria-valuemin={min}
      aria-valuemax={max}
    >
      {!!label && <span className="ProgressBarLabel">{label}</span>}
    </div>
    {!!label && <span className="ProgressBarLabel">{label}</span>}
  </ProgressRoot>
));

ProgressBar.displayName = 'ProgressBar';
ProgressBar.propTypes = propTypes;
export default ProgressBar;
