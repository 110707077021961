
import {useState, useEffect} from 'react';
import { css } from '@mui/system';
import { styled } from '@mui/material/styles';
import usePromisedState from '@twipped/hooks/usePromisedState';
import useClock from '@twipped/hooks/useClock';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import DashboardAnalytics from 'common/models/dashboard-analytics';
import Paper from '@mui/material/Paper';
import {
  Chart,
  BarSeries,
  ArgumentAxis,
  ValueAxis,
} from '@devexpress/dx-react-chart-material-ui';
import { Animation } from '@devexpress/dx-react-chart';


const DashboardGrid = styled(Grid)(css`
  > .MuiGrid-item {
    display: flex;
    justify-content: stretch;
    align-items: stretch;
  }
`);

const DashboardCard = styled(Card)(({ theme }) => css`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;

  .MuiCardContent-root {
    flex: 1 1 auto;
    display: flex;
    align-items: stretch;
  }

  .MuiCardHeader-root {
    padding: 8px 16px;
    border-bottom: 1px solid ${theme.palette.divider};
    text-transform: uppercase;
    font-size: ${theme.typography.button.fontSize};
    font-weight: 600;
  }
`);

const DashboardDaily = styled(Grid)(({ theme }) => css`
  &.DashboardDaily-root {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
  }

  .DashboardDaily-value {
    font-weight: ${theme.typography.fontWeightRegular};
    font-size: ${theme.typography.h1.fontSize};
  }

  .DashboardDaily-label {
    font-size: ${theme.typography.subtitle2.fontSize};
    font-weight: ${theme.typography.fontWeightMedium};
    text-transform: uppercase;
  }

  .MuiLinearProgress-root {
    min-width: 150px;
    height: 6px;
    border-radius: 2px;
  }
`);

function numFormat (input) {
  if (!input || isNaN(input)) return 0;
  return new Intl.NumberFormat('en-us', {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    useGrouping: true,
  }).format(input);
}

export default function DashboardSubPage (props) {

  const [chartData, setChartData] = useState([])
  const tick = useClock(useClock.HOURS);

  const {
    state: analytics = {},
    loading,
  } = usePromisedState(() => (new DashboardAnalytics).admin(), [ tick ]);

  useEffect(async() => {
    var date = new Date();
    const currentYear = date.getFullYear();
    const currentMonth = date.getMonth();
    const lastDay = new Date(currentYear, currentMonth + 1, 0);

    var data = []
    for (var d=1; d<=lastDay.getDate(); d++) {
      data.push({'Day': d, 'Total': 0})
    }

    if (analytics?.closed?.list?.length > 0) {
      for (var item of analytics.closed.list) {
        date = new Date(item.date)
        if (date.getFullYear() == currentYear && date.getMonth() == currentMonth)
        data[date.getDate()-1].Total = item.total
      }
    }

    let diff = data.filter(o1 => !chartData.some(o2 => o1.id === o2.id));
    if (diff.length > 0)
      setChartData(data);
  },[analytics]);

  return (
    <DashboardGrid container spacing={3} {...props}>
      <Grid item xs={12} md={6}>
        <DashboardCard>
          <CardHeader disableTypography title="Today" />
          <CardContent>
            <Grid container spacing={2}>
              <DashboardDaily item xs={4} className="DashboardDaily-root">
                <Typography variant="h3" className="DashboardDaily-value">
                  {loading ? <CircularProgress size={40} /> : numFormat(analytics?.opened?.today)}
                </Typography>
                <LinearProgress
                  variant={loading ? "indeterminate" : "determinate"}
                  value={loading ? 0 : analytics?.opened?.today || 0}
                  max={loading ? 0 : analytics?.opened?.dayAvg || 0}
                  className="DashboardDaily-progress"
                />
                <Typography variant="caption" className="DashboardDaily-label">New Orders</Typography>
              </DashboardDaily>
              <DashboardDaily item xs={4} className="DashboardDaily-root">
                <Typography variant="h3" className="DashboardDaily-value">
                  {loading ? <CircularProgress size={40} /> : numFormat(analytics?.closed?.today)}
                </Typography>
                <LinearProgress
                  variant={loading ? "indeterminate" : "determinate"}
                  value={loading ? 0 : analytics?.closed?.today || 0}
                  max={loading ? 0 : analytics?.closed?.dayAvg || 0}
                  className="DashboardDaily-progress"
                />
                <Typography variant="caption" className="DashboardDaily-label">Deliveries</Typography>
              </DashboardDaily>
              <DashboardDaily item xs={4} className="DashboardDaily-root">
                <Typography variant="h3" className="DashboardDaily-value">
                  {loading ? <CircularProgress size={40} /> : numFormat(analytics?.pastDue?.today)}
                </Typography>
                <LinearProgress
                  variant={loading ? "indeterminate" : "determinate"}
                  value={loading ? 0 : analytics?.pastDue?.today || 0}
                  max={loading ? 0 : analytics?.pastDue?.dayAvg || 0}
                  color="error" className="DashboardDaily-progress"
                />
                <Typography variant="caption" className="DashboardDaily-label">Past Due</Typography>
              </DashboardDaily>
            </Grid>
          </CardContent>
        </DashboardCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <DashboardCard>
          <CardHeader disableTypography title="Month to Date" />
          <CardContent>
            <Grid container spacing={3}>
              <DashboardDaily item xs={4} className="DashboardDaily-root">
                <Typography variant="h3" className="DashboardDaily-value">
                  {loading ? <CircularProgress size={40} /> : numFormat(analytics?.closed?.total)}
                </Typography>
                <LinearProgress
                  variant={loading ? "indeterminate" : "determinate"}
                  value={loading ? 0 : analytics?.closed?.total || 0}
                  max={20000}
                  color="error" className="DashboardDaily-progress"
                />
                <Typography variant="caption" className="DashboardDaily-label">Deliveries</Typography>
              </DashboardDaily>
              <Grid item xs={8}>
                <Paper>
                  <Chart
                    data={chartData}
                    height={100}
                  >
                    <ArgumentAxis />
                    {/* <ValueAxis /> */}
                    <BarSeries
                      valueField="Total"
                      argumentField="Day"
                      barWidth={3}
                    />
                    <Animation />
                  </Chart>
                </Paper>
              </Grid>
            </Grid>
          </CardContent>
        </DashboardCard>
      </Grid>
    </DashboardGrid>
  );
}
