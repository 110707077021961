
import { styled } from '@mui/material/styles';

const PipelineStatusCell = styled('div', {
  name: 'PipelineStatusCell',
  slot: 'Root',
})`
  border-radius: 3px;

  &.vendor_blank {
    width: 28px;
    height: 28px;
    background-repeat:no-repeat;
    display: inline-block;
    background-color: #DDD;
    border-width: 3px;
    border-style: solid;
    border-color: #DDD;
  }

  &.vendor_assignment,
  &.vendor_waiting,
  &.vendor_inprogress,
  &.vendor_complete,
  &.vendor_alert,
  &.vendor_stop,
  &.vendor_message,
  &.vendor_broadcast,
  &.vendor_qcreject,
  &.vendor_qcfixed,
  &.criticalrow
  &.icon-fire,
  &.warningrow
  &.icon-warning-2,
  &.changerow
  &.icon-profile,
  &.vendorrows
  &.icon-flag,
  &.vendorrows
  &.icon-user {
    background-color: #000;
    background-repeat:no-repeat;
    display: inline-block;
    border-style: solid;
    border-color: #f0f0f0;
    border-width: 3px;
  }

  &.vendor_assignment,
  &.vendor_waiting,
  &.vendor_inprogress,
  &.vendor_complete,
  &.vendor_alert,
  &.vendor_stop,
  &.vendor_message,
  &.vendor_broadcast,
  &.vendor_qcreject,
  &.vendor_qcfixed  {
    background-image: url(/common/images/pipeline_sprites.png);
    width: 28px;
    height: 28px;
  }

  &.vendor_assignment {background-position:    3px 3px;}
  &.vendor_waiting    {background-position:  -24px 3px;}
  &.vendor_inprogress {background-position:  -50px 4px;}
  &.vendor_complete   {background-position:  -80px 4px;}
  &.vendor_alert      {background-position: -105px 3px;}
  &.vendor_stop       {background-position: -132px 4px;}
  &.vendor_message    {background-position: -160px 4px;}
  &.vendor_broadcast  {background-position: -189px 4px;}
  &.vendor_qcreject   {background-position: -218px 4px;}
  &.vendor_qcfixed    {background-position: -245px 3px;}

  /* Vendor Status Colors */
  &.green { background-color: #006418; border-color: #006418; }
  &.amber, &.warningrow .icon-warning-2 { background-color: #F7BA00; border-color: #F7BA00; }
  &.red, &.criticalrow .icon-fire { background-color: #860309; border-color: #860309; }
  &.grey   { background-color: #9e9e9e; border-color: #9e9e9e; }
  &.orange { background-color: #e37e2c; border-color: #e37e2c; }
  &.purple { background-color: #410564; border-color: #410564; }
  &.vendorrows .icon-flag, &.changerow .icon-user {
    background-color: #007FFF !important;
    border-color: #007FFF !important;
  }
`;

export default PipelineStatusCell;
