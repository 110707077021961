import usePipelineServer from 'common/pipelines/usePipelineServer';
import Alert from '@mui/material/Alert';


export default function PipelineError () {

	const { error } = usePipelineServer();

	if (!error)
	{
		return null;
	}

	return <Alert severity="error">Pipeline Socket Server unavailable</Alert>
}
