import PropTypes from 'prop-types';
import PipelineListRow from './Row';
import usePipeline from 'common/pipelines/usePipeline';
import usePipelineServer from 'common/pipelines/usePipelineServer';
import { observer } from 'mobx-react-lite';
import Loading from 'pages/loading';

const PipelineList = observer(function PipelineList({ orderTagOptions, onRowClick, rowDetailRender, openRow, onRowToggle }) {
  const {
    pipeline,
    pageLength,
    pageStart,
  } = usePipeline();

  const { error, refresh } = usePipelineServer();

  if (pipeline.empty && pipeline.refreshing && !error) {
    return <Loading />;
  }

  /* eslint-disable no-unused-expressions */
  // Accessing these so that the pipeline re-renders if they change
  pipeline.sortBy;
  pipeline.sortDirection;
  /* eslint-enable no-unused-expressions */

  let rows = [];
  if (pipeline.filteredPipelines) {
    if (pipeline.filteredPipelines.length > 0)
      rows = pipeline.filteredPipelines.slice(pageStart, pageStart + pageLength);
  } else {
    rows = pipeline.slice(pageStart, pageStart + pageLength);
  }

  return rows.map((row, index) => (
    <PipelineListRow
      key={index}
      row={row}
      columns={pipeline.columns}
      href={pipeline.href(row)}
      onRowClick={onRowClick}
      openRow={openRow}
      onRowToggle={onRowToggle}
      refresh={refresh}
      orderTagOptions={orderTagOptions}
    >
      {rowDetailRender && rowDetailRender(row)}
    </PipelineListRow>
  ));
});
PipelineList.displayName = 'PipelineList';
PipelineList.propTypes = {
  rowDetailRender: PropTypes.func,
};

export default PipelineList;
