/* eslint no-shadow: 0 */
import { useCallback } from 'react';
import usePipeline from 'common/pipelines/usePipeline';
import Paginator from 'common/ui/Paginator';

export default function PipelinePagination (props) {
  const {
    setPagination,
    pageLength,
    pageStart,
    totalRows,
  } = usePipeline();

  const onChange = useCallback((pageStart, pageLength) => {
    setPagination({ pageStart, pageLength });
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [ setPagination ]);

  return (
    <Paginator
      {...props}
      pageLength={pageLength}
      pageStart={pageStart}
      totalItems={totalRows}
      onChange={onChange}
    />
  );
}
