import * as React from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { observer } from 'mobx-react-lite';
import usePipeline from 'common/pipelines/usePipeline';
import { usePipelines } from 'common/pipelines/usePipelineCollection';
import NestedDropdown from './NestedDropdown';
import Checkbox from '@mui/material/Checkbox';
import Badge from '@mui/material/Badge';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { styled } from '@mui/material/styles';
import { css } from '@mui/system';
import moment from "moment";

const FilterBox = styled(Box)(css`
  min-width: 120px;
  min-height: 40px;
  margin-left: 24px;
  .MuiBadge-badge {
    padding: 0px 6px;
    right: -12px;
    top: 2px;
    font-weight: 900;
    border-radius: 7px;
    min-width: 34px;
  }
  .MuiMenu-list {
    padding: 8px
  }
`);

const NestedDropdownMenu = styled(NestedDropdown)(
  ({ theme }) => (css`
    color: ${theme.palette.primary.main};
    padding: 8px 24px;
    font-size: 14px;
    min-width: 240px;
  `),
);

const FilterCheckBox = styled(Checkbox)(
  ({ theme }) => (css`
    padding-left: 0 !important;
    color: ${theme.palette.primary.main};
    border-width: 1px;
  `),
);

const MenuItemLabel = styled(Typography)(css`
  min-width: 120px;
  font-size: 12px;
  margin-right: 40px;
`);

const FilterBadge = styled(Typography)(
  ({ theme }) => (css`
    background: ${theme.palette.primary.main};
    color: white;
    font-size: 12px;
    padding: 0px 4px;
    text-align: center;
    font-weight: 900;
    border-radius: 5px;
    position: absolute;
    right: 10px;
    min-width: 32px;
  `),
);

const ClearFilter = styled(MenuItem)(
  ({ theme }) => (css`
    color: ${theme.palette.primary.main};
    font-size: 12px;
    padding-left: 24px;
    text-decoration: underline;
  `),
);

var refreshTimer = null;

const PipelineFilter = observer(function PipelineFilter () {
  const {
    updateTotalRows,
  } = usePipeline();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filterMenu, setFilterMenu] = React.useState(null);
  const [filterSelectedOptions, setFilterSelectedOptions] = React.useState({});
  const pipelines = usePipelines();

  const defaultFilterOptions = [
    {
      label: 'ADMIN ASSIGNED',
      target: 'admin_assigned.name',
      type: 'amount',
      items: {}
    },
    {
      label: 'CLIENT',
      target: 'client.name',
      type: 'amount',
      items: {}
    },
    {
      label: 'DUE DATE',
      target: 'date.due',
      type: 'date',
      items: {
        "Today": 0,
        "Yesterday": 0,
        "Tomorrow": 0,
        "This Week": 0,
        "This Month": 0,
      }
    },
    {
      label: 'ORDERED DATE',
      target: 'date.ordered',
      type: 'date',
      items: {
        "Today": 0,
        "Yesterday": 0,
        "Tomorrow": 0,
        "This Week": 0,
        "This Month": 0,
      }
    },
    {
      label: 'ORDER TAG',
      type: 'tag',
      target: 'tags',
      items: {}
    }
  ]

  React.useEffect(() => {
    setFilterMenu(defaultFilterOptions)
  }, []);

  const getDiffInDays = (startDate, endDate) => {
    if (startDate.toDateString() == endDate.toDateString())
      return 0;

    const yesterday = new Date(endDate)
    yesterday.setDate(yesterday.getDate() - 1)
    if (startDate.toDateString() == yesterday.toDateString())
      return 1;

    const diff = (endDate - startDate) / (1000 * 60 * 60 * 24)
    if (diff < 0 )
      return Math.floor(diff)
    else
      return Math.ceil(diff)
  }

  const objectsEqual = (o1, o2) => 
    JSON.stringify(o1) == JSON.stringify(o2);


  let newFilterOptions = defaultFilterOptions.slice()
  let key;
  let totalRows = 0;
  pipelines.map(pipeline => {
    if (pipeline.empty && pipeline.refreshing) {
      console.log("empty");
    } else {
      // get filter subitems
      for (let option of newFilterOptions) {
        for (let row of pipeline) {
          const item = row.get(option.target);

          if (option.type == 'tag') {
            if(item !== undefined && item !== null) {
              for (let tag of item)
                option.items[tag.label] = 0;
            }
          } else if (option.type != 'date') {
            if (option.label == "ADMIN ASSIGNED" && item == undefined)
              key = "Unassigned"
            else
              key = item
            if (!option.items[key])
              option.items[key] = 0;
          }
        }
      }

      const today = new Date()
      const weekOfYear = moment().format('W')
      let data = pipeline.filteredPipelines ? pipeline.filteredPipelines : pipeline
      if (pipeline.filteredPipelines) totalRows += data.length;
      for (let option of newFilterOptions) {
        for (let row of data) {
          const item = row.get(option.target);

          if (option.type == 'tag') {
            if(item !== undefined && item !== null) {
              for (let tag of item)
                option.items[tag.label] += 1;
            }
          } else {
            if (option.type == 'date' && item) {
              const date = new Date(item)
              const diffInDays = getDiffInDays(new Date(item), today)
              if (diffInDays == 0) key = 'Today'
              else if (diffInDays == 1) key = 'Yesterday'
              else if (diffInDays == -1) key = 'Tomorrow'
              else if (diffInDays > 1 && diffInDays <= 7 && weekOfYear == moment(date).format('W')) key = 'This Week'
              else if (diffInDays <= 31 && date.getMonth() == today.getMonth()) key = 'This Month'
              else key = 'Past Due'
            } else {
              if (option.label == "ADMIN ASSIGNED" && item == undefined)
                key = "Unassigned"
              else
                key = item
            }
            if (option.items[key] != undefined)
              option.items[key] += 1;
          }
        }
      }
    }
  })

  if (!filterMenu || !objectsEqual(newFilterOptions, filterMenu)) {
    setFilterMenu(newFilterOptions)
  }

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getTarget = (label) => {
    for (let option of defaultFilterOptions) {
      if (option.label == label)
        return option.target
    }
  }

  const updateFilterPipelines = (options) => {
    pipelines.map(pipeline => {
      if (options) {
        let pipeline_new = []
        const today = new Date()
        const weekOfYear = moment().format('W')

        for (let menu in options) {
          let rows = pipeline_new.length > 0 ? pipeline_new : pipeline
          pipeline_new = []
          for (let row of rows) {
            for (let submenu of options[menu]) {
              const v = row.get(getTarget(menu));
              if (menu.indexOf("DATE") > 0 && v) {
                const date = new Date(v)
                const diffInDays = getDiffInDays(date, today)
                if (submenu == "Today" && diffInDays == 0 ||
                  submenu == "Yesterday" && diffInDays == 1 ||
                  submenu == "Tomorrow" && diffInDays == -1 ||
                  submenu == "This Week" && diffInDays > 1 && diffInDays <= 7 && weekOfYear == moment(date).format('W') ||
                  submenu == "This Month" && diffInDays <= 31 && date.getMonth() == today.getMonth() ||
                  submenu == "Past Due"
                ) {
                  pipeline_new.push(row);
                }
                break;
              }

              if (menu == "ORDER TAG") {
                for (let tag of v) {
                  if (submenu == tag.label) {
                    pipeline_new.push(row);
                    break;
                  }
                }
              } else if (submenu == v || (v == undefined && (submenu == "undefined" || submenu == "Unassigned"))) {
                pipeline_new.push(row);
              }
            }
          }
        }
        pipeline.filteredPipelines = pipeline_new
      } else {
        pipeline.filteredPipelines = null
      }
    })
  }

  const processFilterOptions = (newfilterSelectedOptions) => {
    if (refreshTimer) {
      clearTimeout(refreshTimer);
      refreshTimer = null;
    }

    refreshTimer = setTimeout(() => {
      refreshTimer = null;
      if (Object.keys(newfilterSelectedOptions).length == 0) {
        updateFilterPipelines(null)
        updateTotalRows(null)
      } else {
        updateFilterPipelines(newfilterSelectedOptions)
        updateTotalRows(newfilterSelectedOptions)
      }
    }, 100, newfilterSelectedOptions);
  }

  const onChangeSubmenuCheckbox = (e, i, submenu) => {
    let newfilterSelectedOptions = {...filterSelectedOptions}
    let menu = filterMenu[i].label
    if (e.target.checked) {
      if (newfilterSelectedOptions[menu] == undefined) newfilterSelectedOptions[menu] = []
      newfilterSelectedOptions[menu].push(submenu)
    } else {
      for (let index=0; index<newfilterSelectedOptions[menu].length; index++) {
        if (newfilterSelectedOptions[menu][index] == submenu)
          newfilterSelectedOptions[menu].splice(index, 1);
      }
      if (newfilterSelectedOptions[menu].length == 0)
        delete newfilterSelectedOptions[menu]
    }

    setFilterSelectedOptions(newfilterSelectedOptions)
    processFilterOptions(newfilterSelectedOptions)
  }
  const getSelectedOrderNumber = (option_index) => {
    let menu = filterMenu[option_index].label
    if (filterSelectedOptions[menu] == undefined) return null

    let count = 0;
    let menu_items = filterMenu[option_index].items
    for (let submenu in menu_items) {
      if (filterSelectedOptions[menu].includes(submenu))
        count += menu_items[submenu];
    }

    // if (count == 0) return null
    return count;
  }
  const getCheckState = (option_index, submenu) => {
    let menu = filterMenu[option_index].label
    if (filterSelectedOptions[menu] && filterSelectedOptions[menu].includes(submenu))
      return true 

    return false;
  }
  const onClearFilterOptions = (e) => {
    setFilterSelectedOptions([])
    updateTotalRows(null)
    updateFilterPipelines(null)
  }

  return (
    <FilterBox>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Filter settings">
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? 'filter-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Badge badgeContent={totalRows > 0 ? totalRows : null} color="error" max={99999}>
              <FilterAltIcon sx={{color: "#0e5fd4", cursor: "pointer"}}/>
            </Badge>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="filter-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 2,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 8,
              width: 18,
              height: 18,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {filterMenu && filterMenu.map((option, i) => (
          <NestedDropdownMenu
            key={`option-${i}`}
            label={option.label}
            badge={getSelectedOrderNumber(i)}
            parentMenuOpen={open}
          >
            {option.items && Object.keys(option.items).map((k, j) => (
              <MenuItem  key={`sub-${j}`} sx={{position: 'relative'}}>
                <label>
                <FilterCheckBox 
                  checked={getCheckState(i, k)}
                  onChange={e => {onChangeSubmenuCheckbox(e, i, k)}}
                  /> 
                <MenuItemLabel sx={{display: 'inline-block'}}>{k}</MenuItemLabel>
                </label>
                <FilterBadge>{option.items[k]}</FilterBadge>
              </MenuItem>
            ))}
          </NestedDropdownMenu>
        ))}
        <ClearFilter onClick={onClearFilterOptions}>Clear All Filters</ClearFilter>
      </Menu>
    </FilterBox>
  );
});
PipelineFilter.displayName = 'PipelineFilter';

export default PipelineFilter;
